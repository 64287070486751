import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import { store } from "@/utils/store.js";
const errorLogColumns = [
  {
    title: "Date",
    dataIndex: "logDateTime",
    key: "date",
  },
  {
    title: "Details",
    key: "details",
    dataIndex: "details",
  },
  {
    title: "Type",
    dataIndex: "logType",
    key: "type",
  },
  {
    title: "User name",
    dataIndex: "userName",
    key: "userName",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];
const inspirientStatusMessagesColumns = [
  {
    title: "Issue Code",
    key: "issueCode",
    scopedSlots: { customRender: "issueCode" },
  },
  {
    title: "Severity",
    key: "severity",
    dataIndex: "severity",
  },
  {
    title: "Title",
    key: "title",
    dataIndex: "title",
  },
];

export default {
  data() {
    return {
      modals: {
        deleteSurveyModal: false,
        cloneSurveyModal: false,
        moveSurveyModal: false,
        loading: false,
        projectServicesModal: false,
        deleteProjectModal: false,
        summary: false,
        summaryLoader: false,
        surveyStatus: false,
        packTestSummary: false,
        inFieldSurveySummary: false,
        errorLogsModal: false,
        uploadReport: false,
        uploadRawData: false,
        quotaList: true,
        mapMakerTestSummary: false,
        uploadTabplan: false,
      },
      customQuestionsList: null,
      activeSurveyName: null,
      inFieldSummaryData: {
        message: {
          rows: [],
        },
      },
      surveySummary: null,
      estimateCompletionDate: null,
      uniqueFriendlyNames: [],
      surveySummaryPrice: null,
      moveProjectID: null,
      projects: [],
      activeProjectServices: null,
      activeProjectServicesID: null,
      prevProjectID: 0,
      selectProject: 0,
      activeProjects: [],
      errorsLogList: [],
      errorLogColumns,
      viewLogPagination: {},
      errorDetailsKey: 0,
      errorDetailsCopy: false,
      currentSurveyStatus: {
        statusId: null,
        statusTitle: null,
        prevTitle: null,
        surveyId: null,
      },
      allSurveyStatus: [],
      surveyStatusValidation: [],
      activeQuotaModule: null,
      infoTabplanUploadData: null,
      tabplanUploading: false,
      uploadTabplanSurveyID: null,
      tabplanProcessStatus: {
        success: false,
        status: "",
        errorMessage: null,
        inspirientID: null,
        inspirientCompleted: false,
        inspirientStatus: null,
        inspirientStatusCode: 0,
        inspirientStatusMessages: null,
      },
      tabplanProcessStatusLoading: false,
      tabplanProcessStatusMessage: "Loading...",
      inspirientStatusMessagesColumns,
      inspirientStatusTablePagination: {},
      reuploadTabplan: false,
      downloadTabplanFileLoading: false,
      tabplanProceedLoading: false,
    };
  },
  computed: {
    sortedUnpinedProjects() {
      return this.projects
        .filter((attr) => attr.Pinned === false)
        .sort((a, b) => {
          return a.ProjectName.localeCompare(b.ProjectName);
        });
    },
    sortedPinedProjects() {
      return this.projects
        .filter((attr) => attr.Pinned)
        .sort((a, b) => {
          return a.ProjectName.localeCompare(b.ProjectName);
        });
    },
    projectsList() {
      const projects = this.projects.filter(
        (project) => project.ProjectId !== this.selectProject
      );
      return projects;
    },
    currentProject() {
      const currentProject = this.projects.filter(
        (project) => project.ProjectId === this.selectProject
      );
      return currentProject;
    },
    sortedProjects() {
      return [...this.sortedPinedProjects, ...this.sortedUnpinedProjects];
    },
  },
  methods: {
    onProductClick(product, event) {
      if (product.SurveyStatus === "Draft") {
        this.getSurveyDetails(product, event);
      } else if (product.SurveyStatus === "Complete") {
        this.viewReport(product, event);
      } else if (
        product.SurveyStatus === "Queued" ||
        product.SurveyStatus === "Report Preparation"
      ) {
        this.getSummaryData(product);
      } else if (
        product.SurveyStatus === "In Field" &&
        this.user.planID === 4
      ) {
        this.activeQuotaModule = product.SurveyID;
      } else if (product.SurveyStatus === "In Field") {
        this.getSummaryData(product);
      } else if (product.SurveyStatus === "In QA") {
        if (
          product.QALink !== "APIFailed" &&
          product.QALink !== null &&
          product.QALink !== ""
        ) {
          window.open(product.QALink, "_blank", "noreferrer");
        } else {
          window.open(product.TestRedirectURL, "_blank", "noreferrer");
        }
      }
    },
    getSurveyDetails(record, event) {
      let wsType;

      if (
        record.SurveyTypeName === "Pack Test" ||
        record.SurveyTypeName === "Pack & SBD"
      ) {
        wsType = "GetPackTestSurveyDetails";
      } else if (record.SurveyTypeName === "Reticle Alignment Test") {
        wsType = "GetSurveyStimReticle";
      } else {
        wsType = "GetCombineSurveyDetails";
      }
      const surveyPromise = new Promise((resolve) => {
        wsUtils[wsType](
          {
            SurveyID: record.SurveyID,
            SurveyTypeID: record.SurveyTypeID,
          },
          resolve
        );
      });

      surveyPromise.then((data) => {
        if (
          event === undefined ||
          !event.target.closest(".js-a-popover-trigger")
        ) {
          this.editSurvay(
            record.SurveyID,
            record.SurveyStatus,
            data,
            record.SurveyTypeID,
            record.addLeg
          );
          jsUtils.setSurveyTypeId(record.SurveyTypeID);
          jsUtils.setSurveyUnEncryptId(record.UnEncryptSId);
        }
      });
    },
    viewReport(e, event) {
      if (
        event === undefined ||
        !event.target.closest(".js-a-popover-trigger")
      ) {
        this.pageLoader = true;
        let apiUrl;

        if (
          e.ReportOption === "Automated" ||
          e.ReportOption === "Custom" ||
          e.ReportOption === "Base"
        ) {
          apiUrl = e.QALink;
        } else if (
          window.location.hostname === "app.hotspexlabs.com" ||
          window.location.hostname === "pg.hotspexlabs.com"
        ) {
          apiUrl = `https://reports.hotspexlabs.com/ReportForms/Index?SurveyId=${e.SurveyID}`;
        } else {
          apiUrl = `https://staging-reports.hotspexstudio.com/ReportForms/Index?SurveyId=${e.SurveyID}`;
        }

        // debugger; // eslint-disable-line
        console.log("apiUrl", apiUrl);
        window.open(apiUrl, "_self", "noreferrer");
        setTimeout(() => {
          this.pageLoader = false;
        }, 1000);
      }
    },
    getSummaryData(data) {
      this.modals.summaryLoader = true;

      this.modals[
        data.SurveyTypeName === "Pack Test" ||
        data.SurveyTypeName === "Pack & SBD"
          ? "packTestSummary"
          : data.SurveyTypeName === "Map Maker"
          ? "mapMakerTestSummary"
          : "summary"
      ] = true;

      let wsType =
        data.SurveyTypeName === "Pack Test" ||
        data.SurveyTypeName === "Pack & SBD"
          ? "GetPackTestSurveyDetails"
          : data.SurveyTypeName === "Map Maker"
          ? "GetMapSurveyDetails"
          : "GetCombineSurveyDetails";
      const surveyPromise = new Promise((resolve) => {
        wsUtils[wsType](
          {
            SurveyID: data.SurveyID,
            SurveyTypeID: data.SurveyTypeID,
          },
          resolve
        );
      });

      surveyPromise.then((data) => {
        this.surveySummary = data;
        if (this.surveySummary !== null && this.surveySummary !== "") {
          if (
            this.surveySummary.SurveyConfig.SurveyTypeName === "Pack Test" ||
            this.surveySummary.SurveyConfig.SurveyTypeName === "Pack & SBD"
          ) {
            this.uniqueFriendlyNames = [
              ...new Set(
                this.surveySummary.selectedProducts.map(
                  (product) => product.FriendlyName
                )
              ),
            ];

            let uniqueCompetitvedeepDiveProducts = [
              ...new Set(
                this.surveySummary.competitvedeepDiveProducts.map(
                  (product) => product.productName
                )
              ),
            ];

            this.surveySummary.uniqueCompetitvedeepDiveProducts =
              uniqueCompetitvedeepDiveProducts;
            this.calculateEstimateCompletionDate();
          } else {
            if (
              this.surveySummary.SurveyConfig.SurveyTypeName !== "Map Maker"
            ) {
              this.getAudienceDetails();
              this.surveySummary.videoItems =
                this.surveySummary.StimDetails.map((item, index) => {
                  return {
                    ...item,
                    sampleSize: this.surveySummary.audience.sampleSize,
                    key: index,
                  };
                });
            } else {
              this.calculateEstimateCompletionDate();
            }
          }
        }

        setTimeout(() => {
          this.modals.summaryLoader = false;
        }, 1000);
      });

      this.getCustomQuestions(data.SurveyID);

      let wsPriceType =
        data.SurveyTypeName === "Pack Test" ||
        data.SurveyTypeName === "Pack & SBD"
          ? "GetPackTestFinalPrice"
          : "GetFinalSurveyPrice";
      const GetFinalSurveyPrice = new Promise((resolve) => {
        wsUtils[wsPriceType](data.SurveyID, resolve);
      });
      GetFinalSurveyPrice.then((data) => {
        this.surveySummaryPrice = data;
      });
    },
    calculateEstimateCompletionDate() {
      const date = new Date(
        this.surveySummary.SurveyConfig.estimateCompletionDate
      );
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      this.estimateCompletionDate = `${month}/${day}/${year}`;
    },
    getInFieldSurveyData(data) {
      this.activeSurveyName = data.SurveyName;
      this.modals.summaryLoader = true;
      this.modals.inFieldSurveySummary = true;

      const getInfieldDetails = new Promise((resolve) => {
        wsUtils.GetInfieldDetails(
          {
            surveyId: data.SurveyID,
          },
          resolve
        );
      });

      getInfieldDetails.then((data) => {
        this.inFieldSummaryData = data;
        setTimeout(() => {
          this.modals.summaryLoader = false;
        }, 1000);
      });
    },
    defineReport(data) {
      this.$router.push({
        name: "define-report",
        params: { id: data.SurveyID },
      });
    },
    openUploadReportModal(record) {
      this.uploadReportSurveyTypeID = record.SurveyTypeID;
      this.modals.uploadReport = true;
      this.uploadReportSurveyID = record.SurveyID;
    },
    openUploadRawDataModal(record) {
      this.modals.uploadRawData = true;
      this.uploadRawDataSurveyID = record.SurveyID;
    },
    openTabplanModal(record) {
      this.modals.uploadTabplan = true;
      this.uploadTabplanSurveyID = record.SurveyID;
      this.getTabplanProcess();
    },
    getTabplanProcess() {
      this.tabplanProcessStatusLoading = true;
      const model = {
        wsName: "TabPlanProcessStatus",
        params: {
          surveyID: this.uploadTabplanSurveyID,
        },
      };

      const getTabplan = new Promise((resolve) => {
        wsUtils.ApiGet(model, resolve);
      });

      getTabplan.then((data) => {
        const handleErrorNotification = () => {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Something went wrong, please try again or contact our support.",
            placement: "bottomLeft",
            duration: 6,
          });
          this.tabplanProcessStatusLoading = false;
          this.modals.uploadTabplan = false;
        };

        if (!data.success) {
          handleErrorNotification();
          return;
        }

        const status = data.status.toLowerCase();

        switch (status) {
          case "process started":
            this.tabplanProcessStatusMessage = "Uploading Tabplan...";

            if (this.modals.uploadTabplan) {
              setTimeout(() => {
                this.getTabplanProcess();
              }, 3000);
            }
            break;

          case "error":
          case "error on uploading inspirient":
            handleErrorNotification();
            break;

          case "upload on inspirient":
            this.tabplanProcessStatus = data;
            this.inspirientStatusTablePagination = {
              ...this.inspirientStatusTablePagination,
              total: data.inspirientStatusMessages.length,
              hideOnSinglePage: true,
              defaultPageSize: 5,
            };
            this.tabplanProcessStatusLoading = false;
            break;

          case "no record found":
          case "cancel uploadTabPlan":
            this.tabplanProcessStatus = data;
            this.tabplanProcessStatusLoading = false;
            break;

          default:
            handleErrorNotification();
            break;
        }
      });
    },
    downloadTabplanFile() {
      this.downloadTabplanFileLoading = true;
      const model = {
        wsName: "getTabPlanZipFile",
        params: {
          surveyID: this.uploadTabplanSurveyID,
        },
      };

      const getTabplanFile = new Promise((resolve) => {
        wsUtils.ApiGet(model, resolve);
      });

      getTabplanFile.then((data) => {
        if (data.success && data.fileURL) {
          const link = document.createElement("a");
          link.href = data.fileURL;
          link.download = "";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          this.$notification["error"]({
            message: "Error!",
            description: "File could not be downloaded.",
            placement: "bottomLeft",
            duration: 6,
          });
        }
        this.downloadTabplanFileLoading = false;
      });
    },
    downloadData(record) {
      let apiUrl;
      if (
        window.location.hostname === "app.hotspexlabs.com" ||
        window.location.hostname === "pg.hotspexlabs.com" ||
        window.location.hostname === "d3n7xxuq0fwznj.cloudfront.net" ||
        window.location.hostname === "d30wz03irsnsp8.cloudfront.net" ||
        window.location.hostname === "d11qiq41szmpd8.cloudfront.net"
      ) {
        apiUrl = `https://admin.hotspexlabs.com/Reports/downloadSurveyData?a=${record.SurveyID}`;
      } else if (window.location.hostname === "app-uat.hotspexstudio.com") {
        apiUrl = `https://admin-uat.hotspexstudio.com/Reports/downloadSurveyData?a=${record.SurveyID}`;
      } else {
        apiUrl = `https://staging-admin.hotspexstudio.com/Reports/downloadSurveyData?a=${record.SurveyID}`;
      }
      window.open(apiUrl, "_blank", "noreferrer");
    },
    showSurveyServicesModal(id, name, modal) {
      this.modals[modal] = true;
      this.activeSurveyID = id;
      this.activeSurveyName = name;
    },
    deleteSurvey(id, surveyName) {
      this.activeSurveyID = id;
      let _this = this;
      this.$confirm({
        title: `Delete a ${surveyName} study`,
        content: () => <div>Are you sure you want to delete this study?</div>,
        onOk() {
          _this.surveyServices("DeleteSurvey", "deleteSurveyModal");
        },
        okText: "Yes",
        onCancel() {},
      });
    },
    viewLog(id, name) {
      this.modals.summaryLoader = true;
      this.modals.errorLogsModal = true;
      const getSurveyLog = new Promise((resolve) => {
        wsUtils.GetSurveyLog(
          {
            surveyId: id,
          },
          resolve
        );
      });

      getSurveyLog.then((data) => {
        this.activeSurveyName = name;
        this.errorsLogList = data;
        const pagination = { ...this.viewLogPagination };
        pagination.total = data.length;
        pagination.hideOnSinglePage = true;
        this.viewLogPagination = pagination;
        setTimeout(() => {
          this.modals.summaryLoader = false;
        }, 1000);
      });
    },
    addLeg(record, event) {
      const addLeg = new Promise((resolve) => {
        wsUtils.AddLeg(
          {
            SurveyID: record.SurveyID,
            SurveyTypeID: record.SurveyTypeID,
            ProjectID: record.ProjectID,
            UserID: this.user.userID,
          },
          resolve
        );
      });

      addLeg.then((data) => {
        record.SurveyID = data.SurveyId;
        record.addLeg = true;
        this.getSurveyDetails(record, event);
      });
    },
    openSurveyStatusModal(data) {
      let title = this.allSurveyStatus.find(
        (item) => item.CodeName === data.SurveyStatus
      );
      this.currentSurveyStatus.prevTitle = data.SurveyStatus;
      if (title !== undefined) {
        this.currentSurveyStatus.statusTitle = title.CodeName;
      }
      this.modals.surveyStatus = true;
      this.currentSurveyStatus.surveyId = data.SurveyID;
    },
    getCustomQuestions(id) {
      const getCustomQuestionsPromise = new Promise((resolve) => {
        wsUtils.GetCustomQuestion(id, resolve);
      });
      getCustomQuestionsPromise.then((data) => {
        this.customQuestionsList = data;
      });
    },
    getAudienceDetails() {
      if (this.surveySummary.audience.categoryUser !== 0) {
        this.surveySummary.audienceDetails = `Category Users / Category usage: within last ${this.surveySummary.audience.categoryUser} months`;
      } else if (
        this.surveySummary.audience.age !== null &&
        this.surveySummary.audience.age.length ===
          ["18-24", "25-34", "35-49", "50-59", "60+"].length &&
        this.surveySummary.audience.gender.length === ["male", "female"].length
      ) {
        this.surveySummary.audienceDetails = "General population";
      } else if (
        this.surveySummary.audience.age !== null &&
        this.surveySummary.audience.gender !== null
      ) {
        this.surveySummary.audienceDetails = `Custom / Gender: ${this.surveySummary.audience.gender.join(
          ", "
        )} / Age: ${this.surveySummary.audience.age.join(", ")}`;
      } else {
        this.surveySummary.audienceDetails = "/";
      }
    },
    studySummaryCloseModal() {
      this.modals.packTestSummary = false;
      this.estimateCompletionDate = null;
      this.uniqueFriendlyNames = [];
    },
    selectMoveProject(value) {
      this.moveProjectID = value;
    },
    getProjectList() {
      const prjectPromise = new Promise((resolve) => {
        // this.loading = true;
        wsUtils.GetProject(
          { clientID: this.client.ClientId, userID: this.user.EncrypteduserID },
          resolve
        );
      });

      prjectPromise.then((data) => {
        this.projects = data;
        // this.loading = false;
        if (this.projects.length === 1) {
          this.selectProject = this.projects[0].ProjectId;
          return;
        }
        if (
          this.activeProjectServices !== "UpdateProjects" &&
          this.activeProjectServices !== "PinProjects" &&
          this.activeProjectServices !== "SaveProject"
        ) {
          if (
            this.activeProjectServices === "DeleteProject" &&
            this.activeProjectServicesID === this.selectProject
          ) {
            this.selectProject = 0;
          } else if (this.prevProjectID === 0) {
            this.selectProject = 0;
          } else {
            let existed = this.projects.filter((project) => {
              return project.ProjectId === this.prevProjectID;
            }).length;

            if (existed === 0) {
              this.selectProject = this.prevProjectID;
            } else {
              this.selectProject = this.projects[0].ProjectId;
            }
          }
        }
        this.activeProjectServices = "";

        let oldClientID = localStorage.getItem("oldClientID");

        if (this.client.ClientId == oldClientID) {
          this.setDefaultProject();
        } else {
          let defaultProject =
            this.sortedProjects.length > 0
              ? this.sortedProjects[0].ProjectId
              : 0;
          jsUtils.setWorkingProject(defaultProject);
          this.prevProjectID = 0;
          this.selectProject = defaultProject;
          sessionStorage.setItem("pID", 8);
          localStorage.setItem("oldClientID", this.client.ClientId);
        }
      });
    },
    setDefaultProject() {
      let selectedProject = jsUtils.getWorkingProject();
      let firstProjectId = this.sortedProjects.length
        ? this.sortedProjects[0].ProjectId
        : null;
      if (selectedProject) {
        this.prevProjectID = selectedProject;
        sessionStorage.setItem("pID", selectedProject);
        this.selectProject = selectedProject;
      } else if (this.sortedProjects.length >= 1) {
        this.prevProjectID = firstProjectId;
        this.selectProject = firstProjectId;
        sessionStorage.setItem("pID", firstProjectId);
      } else {
        this.prevProjectID = 0;
        this.selectProject = 0;
        sessionStorage.setItem("pID", 8);
      }
    },

    surveyServices(endpoint, modal) {
      const surveyServicesPromise = new Promise((resolve) => {
        let data = {
          SurveyId: this.activeSurveyID,
        };

        if (endpoint === "MoveSurvey") {
          data.ProjectId = this.moveProjectID;
        }
        if (endpoint === "DeleteSurvey") {
          data.userId = this.user.EncrypteduserID;
        }
        if (endpoint === "DeleteSurvey") {
          this.loading = true;
        }

        const surveyData = {
          endpoint: endpoint,
          data,
        };
        wsUtils.surveyServices(surveyData, resolve);
        this.modals.loading = true;
      });
      surveyServicesPromise.then(() => {
        this.modals[modal] = false;
        this.moveProjectID = null;
        if (this.$route.name !== "home") {
          this.getSurveyList();
        } else {
          this.getActiveProjects();
        }
        this.modals.loading = false;
      });
    },
    getSurveyList() {
      // projectID : Project ID is Mandatory for "Test Details" and can be passed -1 for "My Test".
      // client : Client Name is Mandatory for both Cases.
      // count : Page Number Use for paging of grid, Not Mandatory pass 0 for all data.
      // pageSize : Grid Record Size, Not Mandatory pass -1 for all data.
      // date : start date, end date
      // launchedBy (email) : Pass to filter records according to launched by in "Test Details", Not Mandatory pass "All" as default.
      // status (SurveyStatusCode) :  Pass to filter records according to Survey Status Code in "Test Details", Not Mandatory pass "All" as default.
      // orderby : Pass to sort records according to columns, Not Mandatory pass "" as default.
      // SurveyType : Pass to filter records in "Test Details" according to Survey Type, Values can be "Image", "Video" , Not Mandatory pass "" for all records.
      const projectID = this.selectProject === 0 ? -1 : this.selectProject;
      this.getSurveyListTriggered = true;
      this.loading = true;
      if (store.defaultSurveyType !== null) {
        this.surveyType = store.defaultSurveyType;
      }
      this.clientObj = {
        projectID: projectID,
        client: this.client.ClientId,
        count: this.curPage,
        pageSize: this.projectPerPage,
        StartDate: this.startDate,
        EndDate: this.endDate,
        launchedBy: this.launchedBy,
        status: this.status,
        orderby: this.orderby,
        SurveyType: this.surveyType,
        userID: this.user.EncrypteduserID,
      };

      const projectListPromise = new Promise((resolve) => {
        wsUtils.GetMyTest(this.clientObj, resolve);
      });

      projectListPromise.then((data) => {
        jsUtils.setSurveysApiTrigger(false);
        this.surveys = data;
        const pagination = { ...this.pagination };
        pagination.total = this.surveys.length;
        pagination.hideOnSinglePage = true;
        this.loading = false;
        this.pagination = pagination;
      });
    },
    getActiveProjects() {
      this.loading = true;
      let data = {
        projectID: -1,
        client: this.client.ClientId,
        count: 0,
        StartDate: "",
        EndDate: "",
        launchedBy: "",
        pageSize: -1,
        status: "9, 7, 4",
        orderby: "",
        SurveyType: "",
        userID: this.user.EncrypteduserID,
      };
      const projectListPromise = new Promise((resolve) => {
        wsUtils.GetMyTest(data, resolve);
      });

      projectListPromise.then((data) => {
        this.activeProjects = data;
        this.loading = false;
      });
    },
    onCloseErrorLogModal() {
      this.activeSurveyName = null;
      this.errorsLogList = [];
    },
    viewLogTableChange(pagination) {
      const pager = { ...this.viewLogPagination };
      pager.current = pagination.current;
      this.viewLogPagination = pager;
    },
    clickErrorLogRow(record, index) {
      return {
        on: {
          click: () => {
            this.viewLogDetails(record, index);
          },
        },
      };
    },
    viewLogDetails(record, index) {
      this.modals.errorLogsModal = true;
      const getSurveyLogDetails = new Promise((resolve) => {
        wsUtils.GetSurveylogDetails(
          {
            id: record.id,
          },
          resolve
        );
      });

      getSurveyLogDetails.then((data) => {
        this.errorsLogList[index].errorDetails = data;
        this.errorDetailsKey = Math.random();
      });
    },
    copy(index) {
      let v1 = "Request:" + this.errorsLogList[index].errorDetails.request;
      let v2 = " Response:" + this.errorsLogList[index].errorDetails.response;
      this.$copyText(v1 + v2).then(
        () => {
          this.$notification["success"]({
            message: "Copy request and response",
            description: "Copied to your clipboard successfully.",
            placement: "bottomLeft",
            duration: 2,
          });
        },
        () => {
          this.$notification["error"]({
            message: "Error!",
            description: "Copy request and response",
            placement: "bottomLeft",
            duration: 2,
          });
        }
      );
    },
    editSurvay(surveyID, surveyStatus, surveyDetails, surveyTypeID, addLeg) {
      console.log('surveyDetails',surveyDetails);
      let typeID = surveyDetails.SurveyConfig.SurveyTypeID;
      if (
        surveyDetails.SurveyConfig.SurveyTypeName === "Pack Test" ||
        surveyDetails.SurveyConfig.SurveyTypeID === 107
      ) {
        let comObjects;
        if (
          surveyDetails.SurveyConfig.CommunicationObjective1 !== 0 &&
          surveyDetails.SurveyConfig.CommunicationObjective1 !== -1
        ) {
          comObjects = 1;
        }
        if (
          surveyDetails.SurveyConfig.CommunicationObjective1 !== 0 &&
          surveyDetails.SurveyConfig.CommunicationObjective1 !== -1 &&
          surveyDetails.SurveyConfig.CommunicationObjective2 !== 0 &&
          surveyDetails.SurveyConfig.CommunicationObjective2 !== -1
        ) {
          comObjects = 2;
        }

        let oldShelfProducts = [];
        surveyDetails.selectedProducts.forEach((product) => {
          if (product.IsOldShelf) {
            oldShelfProducts.push(product);
          }
        });
        let totalNewShelfProducts =
          surveyDetails.selectedProducts.length - oldShelfProducts.length;
        let totaControlProducts = surveyDetails.selectedProducts.filter(
          (e) => e.FriendlyName === "Control"
        ).length;
        let totalLegs = surveyDetails.selectedProducts.filter(
          (e) => e.FriendlyName !== "Control" && !e.IsOldShelf
        );

        let setTotalLegs = 1;
        //If Survey is created from the parent survey
        if (oldShelfProducts.length) {
          setTotalLegs =
            totalNewShelfProducts !== 0
              ? totalNewShelfProducts /
                (totalNewShelfProducts +
                  totaControlProducts -
                  totalNewShelfProducts)
              : 1;
        } else {
          setTotalLegs =
            totalLegs.length !== 0
              ? totalLegs.length /
                  (surveyDetails.selectedProducts.length - totalLegs.length) +
                1
              : 1;
        }

        let packTestPriceSetData = {
          surveyId: surveyID,
          explicitSamplePerDeepDive: surveyDetails.audience.sampleSize,
          legs: setTotalLegs,
          deepDive: surveyDetails.deepDiveProducts.length,
          compDeepDive: surveyDetails.competitvedeepDiveProducts.length,
          comObjects: comObjects,
          ReportOption: surveyDetails.SurveyConfig.ReportOption || "Automated",
          implicitComponent: surveyDetails.SurveyConfig.implicitComponent,
          isCustom: surveyDetails.SurveyConfig.packTestType === "custom",
          Question: surveyDetails.QuestionDetails.CustomQuestions,
        };
        jsUtils.setPackTestPrice(packTestPriceSetData);
      } else if (surveyDetails.SurveyConfig.SurveyTypeID === 108) {
        let surveyPriceSetData = {
          SurveyId: surveyID,
          SurveyType: surveyTypeID,
          ClickSpex: true,
          Stims: [],
          Question: surveyDetails.QuestionDetails.CustomQuestions,
          ReportOption: "Automated",
          Audience: {
            sampleSize: 200,
            categoryUser: 0,
            genPop: 1,
          },
        };
        jsUtils.setSurveyPrice(surveyPriceSetData);
      } else {
        let videos = [{ duration: 100 }];
        if (surveyDetails.StimDetails.length !== 0) {
          videos = surveyDetails.StimDetails.map((item) => {
            return { duration: item.AdDuration };
          });
        }

        const brandHelthType = typeID === 92;

        let surveyPriceSetData = {
          SurveyId: surveyID,
          SurveyType: surveyTypeID,
          ClickSpex: true,
          Stims: brandHelthType ? [] : videos,
          Question: [],
          Audience: {
            sampleSize: surveyDetails.audience.sampleSize,
            categoryUser: surveyDetails.audience.categoryUser,
            genPop: surveyDetails.audience.genPop,
          },
        };
        if (surveyTypeID === 97) {
          surveyPriceSetData.ReportType =
            surveyDetails.SurveyConfig.ReportOption || "Automated";
        } else {
          surveyPriceSetData.ReportType =
            surveyDetails.SurveyConfig.ReportOption || "Base";
        }
        jsUtils.setSurveyPrice(surveyPriceSetData);
      }

      let surveyRouteName;

      const surveyRouteNames = {
        8: "vid-setting",
        93:
          surveyDetails.SurveyConfig.packTestType === "custom"
            ? "pack-test-custom-setting"
            : "pack-test-setting",
        97: "comms-setting",
        98: "vid-ua-setting",
        99: "vid-yt-ua-setting",
        101: "vid-tiktok-setting",
        102: "vid-reticle-setting",
        103: "vid-tiktok-ua-setting",
        107: "pack-sbd-test-setting",
        108: "map-maker-settings",
      };

      surveyRouteName = surveyRouteNames[typeID];

      let routeName;
      if (surveyDetails.SurveyConfig.isLaunchedWithoutPO) {
        if (typeID === 93) {
          routeName = "pack-test-launch";
        } else if (typeID === 107) {
          routeName = "pack-sbd-test-launch";
        } else if (typeID === 108) {
          routeName = "map-maker-test-launch";
        } else {
          routeName = surveyRouteName;
        }
      } else {
        routeName = surveyRouteName;
      }

      if (surveyStatus === "Draft" || addLeg) {
        this.$router.push({
          name: routeName,
          params: { id: surveyID },
        });
        jsUtils.setEditableSurvey(surveyDetails);
        jsUtils.setRunSurveyMode(true);
      }
      jsUtils.deleteCookie("survey-type");
    },
    setSurveyStatus(data) {
      this.currentSurveyStatus.statusId = data;
      this.currentSurveyStatus.statusTitle = this.allSurveyStatus.find(
        (item) => item.CodeId === data
      ).CodeName;
    },
    getSurveyStatus() {
      const surveyStatus = new Promise((resolve) => {
        wsUtils.GetSurveyStatus(resolve);
      });

      surveyStatus.then((data) => {
        this.allSurveyStatus = data;
      });
    },
    resetCurrentSurveyStatus() {
      this.currentSurveyStatus = {
        statusId: null,
        statusTitle: null,
        surveyId: null,
      };
    },
    saveSurveyStatus() {
      this.surveyStatusValidation = [];
      if (this.currentSurveyStatus.statusId !== null) {
        const updateSurveyStatus = new Promise((resolve) => {
          wsUtils.UpdateSurveyStatus(
            {
              surveyId: this.currentSurveyStatus.surveyId,
              statusId: this.currentSurveyStatus.statusId,
              userId: this.user.EncrypteduserID,
            },
            resolve
          );
        });

        updateSurveyStatus.then((data) => {
          if (data.responseCode) {
            this.$notification["success"]({
              message: "Update study status",
              description: "Study status is successfully updated.",
              placement: "bottomLeft",
              duration: 5,
            });
            if (this.$route.name !== "home") {
              this.getSurveyList();
            } else {
              this.getActiveProjects();
            }
            this.resetCurrentSurveyStatus();
          } else {
            this.$notification["error"]({
              message: "Update study status!",
              description:
                "Something was wrong, please try again or contact our support.",
              placement: "bottomLeft",
              duration: 5,
            });
          }

          this.modals.surveyStatus = false;
        });
      } else {
        this.surveyStatusValidation.push("Please choose a study status.");
      }
    },
    closeUploadTabplanModal() {
      this.modals.uploadTabplan = false;
      this.tabplanProcessStatus = {
        success: false,
        status: "",
        errorMessage: null,
        inspirientID: null,
        inspirientCompleted: false,
        inspirientStatus: null,
        inspirientStatusCode: 0,
        inspirientStatusMessages: null,
      };
      this.reuploadTabplan = false;
    },
    tabplanData(info) {
      this.infoTabplanUploadData = info;
    },
    submitTabplanUpload() {
      this.tabplanUploading = true;
      let form = new FormData();
      form.append("file", this.infoTabplanUploadData.file);
      form.append("surveyId", this.uploadTabplanSurveyID);
      form.append("userId", this.user.EncrypteduserID);

      const data = {
        wsName: "UploadTabPlan",
        data: {
          form: form,
        },
      };

      const uploadTabplanFile = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      uploadTabplanFile.then((data) => {
        if (data.success) {
          this.$notification["success"]({
            message: "Success!",
            description: "Tabplan uploaded successfully.",
            placement: "bottomLeft",
            duration: 4,
          });
          this.reuploadTabplan = false;
          this.getTabplanProcess();
        } else {
          this.$notification["error"]({
            message: "Error!",
            description: data.message,
            placement: "bottomLeft",
            duration: 5,
          });
        }
        this.infoTabplanUploadData = null;
        this.tabplanUploading = false;
        // this.modals.uploadTabplan = false;
      });
    },
    handleInspirientStatusTableChange(pagination) {
      const pager = { ...this.inspirientStatusTablePagination };
      pager.current = pagination.current;
      this.inspirientStatusTablePagination = pager;
    },
    tabplanProceed() {
      this.tabplanProceedLoading = true;
      const setTabplanProceed = new Promise((resolve) => {
        wsUtils.TabPlanProceed({surveyId: this.uploadTabplanSurveyID, userId: this.user.EncrypteduserID}, resolve);
      });

      setTabplanProceed.then((data) => {
        if (data.success) {
          this.$notification["success"]({
            message: "Success!",
            description: "Currently processing your data...please hold.",
            placement: "bottomLeft",
            duration: 4,
          });
        } else {
          this.$notification["error"]({
            message: "Error!",
            description: "Something went wrong, please try again.",
            placement: "bottomLeft",
            duration: 5,
          });
        }
        this.tabplanProceedLoading = false;
      });
    },
  },
  activated() {
    this.client = jsUtils.getCurClient();
    this.getProjectList();
  },
  created() {
    this.getSurveyStatus();
  },
};
