<template>
  <div class="mb-20">
    <a-table
      tableLayout="auto"
      :columns="columns"
      class="custom-table custom-table--remove-hover"
      :row-key="(record) => record.configNum"
      :data-source="downloadsList"
      :pagination="{ hideOnSinglePage: true }"
    >
      <div slot="action" class="flex gap-15 justify-center" slot-scope="record">
        <a-tooltip>
          <template slot="title"> Download the PPT file </template>
          <button
            class="text-btn text-btn--ppt text-left font-size-24"
            type="link"
            @click="downloadFile(record.pptFileURL)"
          >
            <font-awesome-icon icon="file-powerpoint" />
          </button>
        </a-tooltip>
        <a-tooltip>
          <template slot="title"> Download the Excel file </template>
          <button
            class="text-btn text-btn--excel text-left font-size-24"
            type="link"
            @click="downloadFile(record.excelFileURL)"
          >
            <font-awesome-icon icon="file-excel" />
          </button>
        </a-tooltip>
      </div>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: "Name",
    dataIndex: "configName",
  },
  {
    title: "Number",
    dataIndex: "configNum",
  },
  {
    title: "Date",
    dataIndex: "created_date",
  },

  {
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "DownloadsPreviewModal",
  props: {
    downloadsList: {
      type: Array,
    },
  },
  data() {
    return {
      columns,
    };
  },
  computed: {},
  methods: {
    downloadFile(url) {
      if (url) {
        const link = document.createElement("a");
        link.href = url;
        link.download = "";
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
  created() {},
  watch: {},
};
</script>

<style lang="scss" scoped></style>
