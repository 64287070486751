<template>
  <div class="body-bcg shelf-search">
    <a-breadcrumb class="breadcrum">
      <template v-if="user !== null && user.SkinIdentifier !== 'pg'">
        <a-breadcrumb-item>
          <router-link to="/">{{
            client ? client.ClientName : ""
          }}</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item
          ><router-link to="/shelf-search"
            >Project Planning Tool</router-link
          ></a-breadcrumb-item
        >
      </template>
    </a-breadcrumb>

    <div class="page-padding">
      <div class="page-title">
        <icon-base
          :width="32"
          :height="33"
          :viewBox1="24"
          :viewBox2="24"
          iconName="plan"
          iconColor="#4318FF"
          ><icon-plan
        /></icon-base>
        <div class="page-title__text">
          <h1>Project Planning Tool</h1>
          <span>Detailed Pricing for Your Project</span>
        </div>
      </div>

      <div class="box-wrapper box-wrapper--settings-form max-w-970">
        <div class="section-info mb-35">
          <p>
            <span class="red">Before we get into the details:</span> provide
            your category, target market, the number of legs you want to test,
            the number of packs you want to dive deeper into, and your optimal
            sample size to find the ideal fit with your budget. To discuss your
            project needs in more detail, please click the Support icon below
            and one of our experts will be happy to get back to you today. To
            further understand the framework and innovative methods behind our
            service, please click
            <span class="blue cursor-pointer" @click="methodologyModal = true"
              >here</span
            >
            to explore our methodology.
          </p>
        </div>
        <div class="box-wrapper__inner">
          <p class="font-size-14 mb-20">*All fields are required.</p>
          <div class="text-right">
            <span class="blue cursor-pointer" @click="platformQuizModal = true"><strong>Should I use the platform?</strong></span>
          </div>
        </div>
        <!-- end -->
        <div class="box-wrapper__inner">
          <div class="input-group">
            <label>Select a category</label>
            <div class="position-r">
              <Skeleton
                :borderRadius="16"
                v-if="shelfCategoryLoader"
              ></Skeleton>
              <a-select
                class="fullWidth"
                option-filter-prop="children"
                show-search
                placeholder="Select a category"
                :key="Math.random()"
                :default-value="chosenShelfCategory"
                @change="chosenShelfCategory = $event"
              >
                <a-select-option
                  v-for="item in shelfCategoryInfo"
                  :key="item.CetegoryName"
                  :value="item.CetegoryName"
                >
                  {{ item.CetegoryName }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="input-group">
            <label>Select a market</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="shelfMarketLoader"></Skeleton>
              <a-select
                class="fullWidth"
                option-filter-prop="children"
                show-search
                :key="Math.random()"
                placeholder="Select a market"
                :default-value="chosenShelfCountry"
                @change="chosenShelfCountry = $event"
              >
                <a-select-option
                  v-for="item in shelfMarketInfo"
                  :key="item.CountryName"
                  :value="item.CountryName"
                >
                  {{ item.CountryName }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="input-group">
            <div class="flex items-center">
              <label class="lineLable">Number of legs</label>
              <a-tooltip
                placement="top"
                overlayClassName="ant-tooltip--step-nav"
              >
                <template slot="title">
                  <span
                    >Including your current design, how many different designs
                    do you want to test? This will be your total number of
                    cells.</span
                  >
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </div>
            <a-input-number
              class="fullWidth"
              size="large"
              type="number"
              :min="1"
              :max="100000"
              v-model="legsNumber"
            />
          </div>
          <div class="input-group">
            <div class="flex items-center">
              <label class="lineLable">Number of deep dive Packs</label>
              <a-tooltip
                placement="top"
                overlayClassName="ant-tooltip--step-nav"
              >
                <template slot="title">
                  <span
                    >How many priority products do you want full packaging
                    diagnostics for? Full packaging diagnostics include: on-pack
                    navigation, affective heuristics, automatic implicit
                    associations, pack functionality and detailed sentiment
                    feedback on specific pack elements.</span
                  >
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </div>
            <a-input-number
              class="fullWidth"
              size="large"
              type="number"
              :min="1"
              :max="100000"
              v-model="deepDiveNumber"
            />
          </div>
          <div class="input-group">
            <div class="flex items-center">
              <label class="lineLable">Evaluations per Pack</label>
              <a-tooltip
                placement="top"
                overlayClassName="ant-tooltip--step-nav"
              >
                <template slot="title">
                  <span
                    >This will determine the number of consumers doing full
                    packaging diagnostics for each priority product.</span
                  >
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </div>
            <a-select
              class="fullWidth"
              option-filter-prop="children"
              show-search
              placeholder="Select a category"
              :key="Math.random()"
              :default-value="sampleNumber"
              @change="sampleNumber = $event"
            >
              <a-select-option
                v-for="item in sampleSize"
                :key="item"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </div>
          <div class="input-group">
            <div class="flex items-center">
              <label class="lineLable">Implicit</label>
            </div>
            <a-select
              class="fullWidth"
              option-filter-prop="children"
              show-search
              placeholder="Select implicit"
              :key="Math.random()"
              :default-value="implicitValue"
              @change="implicitValue = $event"
            >
              <a-select-option
                v-for="item in isImplicit"
                :key="item"
                :value="item.value"
              >
                {{ item.text }}
              </a-select-option>
            </a-select>
          </div>
          <div class="input-group">
            <div class="flex items-center">
              <label class="lineLable">Incidence rate</label>
            </div>
            <a-input-number
              class="fullWidth "
              size="large"
              type="number"
              :class="getErrorStatus(incidenceRate, 'incidenceRate')"
              :min="1"
              :max="100"
              v-model="incidenceRate"
            />
            <a-form-item :validateStatus="getErrorStatus(incidenceRate, 'incidenceRate')">
              <a-space size="small" v-if="getErrorStatus(incidenceRate, 'incidenceRate')" >
                <a-alert
                  :message="getErrorMessage(incidenceRate, 'incidenceRate')"
                  type="error"
                  show-icon 
                />
              </a-space>
            </a-form-item>
          </div>
           <div class="input-group">
            <div class="flex items-center">
              <label class="lineLable">Custom quotas</label>
              <a-tooltip
                placement="top"
                overlayClassName="ant-tooltip--step-nav"
              >
                <template slot="title">
                  <span
                    >Custom quotas are any quotas that aren't included in your demographic quotas (i.e. Age, gender, income, ethnicity). These will incur extra tech costs and potentially add 1-2 days of extra time.</span
                  >
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </div>
            <a-input-number
              class="fullWidth"
              size="large"
              type="number"
              :class="getErrorStatus(customQuotas, 'customQuotas')"
              :min="0"
              :max="100000"
              v-model="customQuotas"
            />
            <a-form-item :validateStatus="getErrorStatus(customQuotas, 'customQuotas')">
              <a-space size="small" v-if="getErrorStatus(customQuotas, 'customQuotas')" >
                <a-alert
                  :message="getErrorMessage(customQuotas, 'customQuotas')"
                  type="error"
                  show-icon 
                />
              </a-space>
            </a-form-item>
          </div>
        </div>
      </div>
      <div class="flex justify-end max-w-970">
        <a-button
          type="primary"
          :disabled="viewDisabled"
          class="mt-25"
          @click="
            $router.push({
              path: '/shelf-library',
              query: {
                category: chosenShelfCategory,
                market: chosenShelfCountry,
                legs: legsNumber,
                deepDive: deepDiveNumber,
                sampleSize: sampleNumber,
                implicit: implicitValue,
                incidenceRate: incidenceRate,
                quotas: customQuotas || 0
              },
            })
          "
          >Get Pricing
        </a-button>
      </div>
    </div>
    <a-modal
    title="Should I use the platform?"
      :visible="platformQuizModal"
      @cancel="closePlatformQuiz"
      :width="660"
      centered
    >
    <div v-if="showQuizQuestions" class="text-center">
      <p class="font-size-16 font-500 mb-35">{{ currentQuestion.text }}</p>
      <div class="box-wrapper__inner">
        <a-button type="primary" @click="answerQuestion('yes')">Yes</a-button>
        <a-button type="primary" @click="answerQuestion('no')">No</a-button>
      </div>
    </div>
    <div v-if="showResult" id="result" class="text-center">
      <div style="width: 140px; height: 140px; margin: auto;" > 
        <font-awesome-icon :icon="resultIcon" class="fullWidth h-full" :style="{ color: resultColor }"/>
      </div>
      <p class="font-size-16 font-500 mt-35">{{ resultMessage }}</p>         
    </div>
      <template #footer>
        <a-button key="back" type="primary" @click="closePlatformQuiz"
          >Cancel</a-button>
      </template>
    </a-modal>

    <a-modal
      title="
        Our Methodology
      "
      :visible="methodologyModal"
      @cancel="methodologyModal = false"
      :width="660"
    >
      <div class="shelf-search__col-inner">
        <p>
          Our goal is to support the growth of the world’s most iconic brands
          and help to drive superiority across all of your categories.
        </p>
        <p class="mb-5">
          The Hotspex 3D virtual store environment allows for Immersive digital
          shopper observation, unlocking insights on how your packaging impacts
          shopping behavior via Hotspex’s trusted framework:
        </p>
        <ul>
          <li>
            <strong>See:</strong> Be distinctive and familiar, making it easy to
            see and recognize the brand.
          </li>
          <li>
            <strong>Select:</strong> Make it easy to select the right product
            for her/him
          </li>
          <li>
            <strong>Buy:</strong> Superior Packaging / First Moment of Truth
            experience that encourages consumers to buy your product
          </li>
        </ul>
        <p class="mb-5">
          We then dive deeper into the consumer’s interaction with your
          packaging and assess its ability to reinforce relevant brand
          associations via:
        </p>
        <ol>
          <li>On-pack navigation</li>
          <li>
            Affective heuristics, Automatic Implicit Associations & Pack
            Functionality
          </li>
          <li>Detailed Sentiment Feedback on specific pack elements</li>
        </ol>
      </div>
      <template #footer>
        <a-button key="back" type="primary" @click="methodologyModal = false"
          >Cancel</a-button
        >
      </template>
    </a-modal>

    <StepNaviBar
      :cancelButton="false"
      :conciergeAssistance="true"
      :generalNaviBar="true"
    />
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import Skeleton from "@/components/general/Skeleton.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar1.vue";
import IconBase from "@/components/general/IconBase.vue";
import IconPlan from "@/components/icons/IconPlan.vue";

export default {
  name: "ShelfSearch",
  components: {
    Skeleton,
    StepNaviBar,
    IconBase,
    IconPlan,
  },
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      shelfLibraryInfo: [],
      shelfInfoLoader: false,
      chosenShelfCategory: "",
      chosenShelfCountry: "",
      shelfCategoryInfo: [],
      shelfMarketInfo: [],
      shelfLoader: true,
      shelfCategoryLoader: false,
      shelfMarketLoader: false,
      sampleSize: [100, 150, 200, 300],
      isImplicit: [{"text":"Yes","value":true}, {"text":"No", "value":false}],
      legsNumber: 1,
      deepDiveNumber: 1,
      sampleNumber: 100,
      implicitValue: false,
      incidenceRate: 11,
      customQuotas: 0,
      methodologyModal: false,
      user: null,
      client: null,
      platformQuizModal: false,
      platformQuestions: [
        { text: "Will your survey have an IR over %10?", weight: 40, yes: 40, no: -40 },
        { text: "Does anyone on your team have capacity to monitor and manage sampling? (~30 mins to an hour a day)", weight: 20, yes: 20, no: -20 },
        { text: "Will your legs have an equal number of deep dive/pack assignments?", weight: 10, yes: 10, no: -10 },
        { text: "Will your shopping environment have more than ONE shoppable shelf within a single cell?", weight: 10, yes: -10, no: 10 },
        { text: "Is there an existing shelf that can be used as a base for this project?", weight: 10, yes: 10, no: -10 },
        { text: "Will your survey have quotas other than demographics?", weight: 2, yes: -2, no: 2 },
        { text: "If yes to the previous question, do any of your custom quotas require more than one question to define (for eg. respondent has to answer one option at Question A AND another option at Question B)?", weight: 2, yes: -2, no: 2 },
        { text: "Does your study HAVE to use product lineups (vs. individual product image) for stated measures?", weight: 2, yes: -2, no: 2 },
        { text: "Is it acceptable for this research for age ranges and region to be asked to the respondent directly (rather than coded based on specific provinces and age answers)?", weight: 2, yes: 2, no: -2 },
        { text: "Will your survey require more than 8 categories, 40 brands and/or 15 cogni statements?", weight: 2, yes: -2, no: 2 }
      ],
      currentQuestionIndex: 0,
      score: 0,
      showResult: false,
      showQuizQuestions: true,
      resultMessage: "",
      resultColor: "",
      resultIcon: "",
    };
  },
  computed: {
    viewDisabled() {
      return (
        !this.legsNumber ||
        !this.deepDiveNumber ||
        !this.chosenShelfCategory ||
        !this.chosenShelfCountry ||
        !this.incidenceRate || 
        this.getErrorStatus(this.incidenceRate, 'incidenceRate') === 'error'
      );
    },
    currentQuestion() {
      return this.platformQuestions[this.currentQuestionIndex];
    }
  },
  methods: {
    shelfCategory() {
      this.shelfCategoryLoader = true;
      const getShelfCategory = new Promise((resolve) => {
        wsUtils.GetShelfCategory(
          {
            userId: this.user.EncrypteduserID,
          },
          resolve
        );
      });

      getShelfCategory.then((data) => {
        this.shelfCategoryInfo = data;
        this.shelfCategoryLoader = false;
      });
    },
    shelfMarket() {
      this.shelfMarketLoader = true;
      const getShelfMarket = new Promise((resolve) => {
        wsUtils.GetShelfMarket(
          {
            userId: this.user.EncrypteduserID,
          },
          resolve
        );
      });

      getShelfMarket.then((data) => {
        this.shelfMarketInfo = data;
        this.shelfMarketLoader = false;
      });
    },
    getErrorStatus(value, field) {
      if (field === 'incidenceRate'){
        if (value === null || value === undefined || value === '') {
          return 'error';
        } else if (isNaN(value) || value < 1 || value > 100) {
          return 'error';
        } else if (value <= 10) {
          return 'error';
        } else {
          return '';
        }
      } else if (field === 'customQuotas') {
        if (value === null || value === undefined || value === '') {
          return '';
        } else if (isNaN(value) || value < 0 || value > 100000) {
          return 'error';
        } else if (value > 8) {
          return 'error';
        } else {
          return '';
        }
      }
    },
    getErrorMessage(value, field) {
      if (field === 'incidenceRate'){
        if (value === null || value === undefined || value === '') {
          return 'You must enter a number value here';
        } else if (isNaN(value) || value < 1 || value > 100) {
          return 'Incidence rate must be a numeric value between 1 and 100';
        } else if (value <= 10) {
          return 'This IR is not feasible for the platform';
        } else {
          return '';
        }
      } else if (field === 'customQuotas') {
        if (value === null || value === undefined || value === '') {
          return '';
        } else if (value > 8) {
          return 'This number of custom quotas is not feasible for the platform';
        } else {
          return '';
        }
      }
    },
    answerQuestion(answer) {
      if (answer === "yes") {
        this.score += this.currentQuestion.yes;
      } else {
        this.score += this.currentQuestion.no;
      }

      if (this.currentQuestionIndex < this.platformQuestions.length - 1) {
        this.currentQuestionIndex++;
      } else {
        this.calculateResult();
      }
    },
    calculateResult() {
      this.showQuizQuestions = false;
      this.showResult = true;

      if (this.score <= 50) {
        this.resultColor = "red";
        this.resultIcon = "times-circle";
        this.resultMessage = "Sorry, we recommend NOT utilizing the platform for this research :/";
      } else if (this.score <= 79) {
        this.resultColor = "grey";
        this.resultIcon = "minus-circle";
        this.resultMessage = "This is viable for the platform, but proceed with caution. You should anticipate additional tech costs and 1-2 days extra timing to manufacture your study.";
      } else {
        this.resultColor = "green";
        this.resultIcon = "check-circle";
        this.resultMessage = "Congratulations! This project is a good fit for the platform :)";
      }
    },
    closePlatformQuiz() {
      this.platformQuizModal = false;
      this.resetQuiz();
    },
    resetQuiz() {
      this.currentQuestionIndex = 0;
      this.score = 0;
      this.showResult = false;
      this.resultMessage = "";
      this.resultColor = "";
      this.showQuizQuestions = true;
    }
  },
  activated() {
    this.user = jsUtils.getUserInfo();
    this.client =
      jsUtils.getCurClient() || JSON.parse(jsUtils.readCookie("HS-STUDIO"));
    this.shelfCategory();
    this.shelfMarket();
  },  
};
</script>

<style lang="scss" scoped>
.shelf-search {
  .page-title {
    svg {
      stroke-width: 0;
    }
  }
  &__col-inner {
    h2 {
      border-top: 1px solid #dbdbdb;
      padding: 21px 0 4px;
      margin: 22px 0 10px;
      font-size: 16px !important;
    }
    p {
      font-size: 16px;
    }
    ul,
    ol {
      padding: 0;
      margin-bottom: 20px;
      li {
        margin-bottom: 5px;
        font-size: 16px;
        strong {
          display: block;
        }
      }
    }
    ol {
      padding-left: 18px;
    }
    
  }
  .ant-space-item{
    width: 100%;
  }
  .ant-space{
    width: 100%;
  }
  .input-group .ant-input-number-status-error {
    border-color: red !important;
    box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.2) !important;
  }
  .text-right{
    text-align: right;
  }
  .blue{
    color: var(--blue);
  }
}
.ant-modal #result .fullWidth{
  width: 100%;
}
</style>
